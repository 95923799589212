<template>
  <div>
    <CommonTopBanner title="Built to protect. Designed to impress."
      sub-title="Elevate your home’s protection and style with our expert remodeling services"
      cta-text="Free Estimate"
      :banner-features="['Free estimates & quotes', 'Home consultations', 'Single day installs', 'Best-in-class materials', 'Licensed, insured, certified', 'Veteran operated']" />
    <LazyCommonCertifiedBy />
    <LazyMainIntroduction />
    <CommonCustomerSaying />
    <LazyMainTeamMember />
    <LazyCommonOneStopRoofing />
    <LazyMainNewsAndArticle />
  </div>
</template>

<script setup lang="ts">
useHead({
  title: 'Canopy Home Exteriors - Home'
})
useSeoMeta({
  title: 'Canopy Home Exteriors - Home',
  ogTitle: 'Canopy Home Exteriors - Home',
  description: 'Canopy Home Exteriors serving the Atlanta area.',
  ogDescription: 'Canopy Home Exteriors serving the Atlanta area.'
  // ogImage: 'https://example.com/image.png',
  // twitterCard: 'summary_large_image'
})
</script>
